import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { addcart } from "../redux/action/index";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import "react-image-gallery/styles/css/image-gallery.css";
import { Modal, Tooltip } from "antd";
import "antd/dist/antd.css";
import securepartners from "../Assets/Images/secure-partners.png";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import sitelogo from "../Assets/Images/sitelogo.png";
import { PositionableContainer, Position } from "re-position";
import html2canvas from "html2canvas";
import jsonImage from "../productImages.js";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

function Product() {
	window.localStorage.clear();
	let navigate = useNavigate();
	const [nftFetchLoop, setNftFetchLoop] = useState(1);
	const [incrementPage, setIncrementPage] = useState(1);
	const nftUpload = useRef(null)
	const { id } = useParams();
	const [product, setProduct] = useState("");
	const [loading, setLoading] = useState(false);
	const [picture, setPicture] = useState(null);
	const [imgData, setImgData] = useState(null);
	const [quantity, setQuantity] = useState(1);
	const [nfts, setNFTS] = useState();
	const [address, setAddress] = useState();
	const [nftArrayState, setFftArrayState] = useState([]);
	const [show, setShow] = useState(false);
	const [showLoadNfts, setShowLoadNfts] = useState(true);
	const [nftimages, setNftImages] = useState("");
	const [nftFetchContinuation, setNftFetchContinuation] = useState('')
	const [selectedImage, setselectedImage] = useState("");
	const [onlyOnce, setOnlyOnce] = useState(false);
	const account_address = useSelector((state) => state.handleAccount);
	console.log(account_address);
	console.log("===================== account_address", account_address);
	const dispatch = useDispatch();

	function dataURItoBlob(dataURI) {
		// convert base64/URLEncoded data component to raw binary data held in a string
		let byteString;
		if (dataURI.split(",")[0].indexOf("base64") >= 0) byteString = atob(dataURI.split(",")[1]);
		else byteString = unescape(dataURI.split(",")[1]);

		// separate out the mime component
		let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

		// write the bytes of the string to a typed array
		let ia = new Uint8Array(byteString.length);
		for (let i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}

		return new Blob([ia], { type: mimeString });
	}
	const addproduct = (product) => {
		// console.log({...product, selectedSize, selectedValue}, 'product')
		html2canvas(document.getElementById("product-overview"), { useCORS: true, logging: true, letterRendering: 1, allowTaint: true }).then(async (canvas) => {
			let blob = dataURItoBlob(canvas.toDataURL("image/png"));
			let image = new File([blob], product.title, { type: blob.type });
			let fd = new FormData();
			fd.append("file", image, image.type);
			try {
				let response = await axios.post("https://api.tangibletokenz.com/api/file/upload", fd);
				let url = `https://api.tangibletokenz.com${response.data.data}`;
				console.log(response.data.data, "responseeee<<<<");
				console.log(url);
				console.log(selectedSize);
				product.selectedProductDetail = { size: selectedSize, color: selectedValue };
				setProduct({ ...product, selectedSize, selectedValue, selectedThumb, selectedImage, cartImage: url });
				dispatch(addcart({ ...product, selectedSize, selectedValue, selectedThumb, selectedImage, cartImage: url }));
				if (product) {
					navigate("/cart");
				}
			} catch (err) {
				console.log(err);
			}
		});
	};

	useEffect(() => {

		if (!account_address) {
			setNftImages("");
		}
		setselectedSize(1);
		const getProduct = async () => {
			setLoading(true);
			let data;
			console.log(jsonImage, "updatedArray");
			for (let i = 0; i < jsonImage.length; i++) {
				if (jsonImage[i].id == id) {
					data = jsonImage[i];
					switch (data.id) {
						case 40999393001678:
							data = {
								...data,
								cartStyle: {
									transform: "translate(-14%, -60%)",
								},
							};
							break;
						case 41138846007502:
							data.size = data.size.map((size) => {
								let style;
								switch (size.id) {
									case 1:
										style = {
											json_width: 35,
											json_height: 35,
											json_left: 45,
											json_top: 12,
										};
										break;
									case 2:
										style = {
											json_width: 45,
											json_height: 43,
											json_left: 40,
											json_top: 10,
										};
										break;
									case 3:
										style = {
											json_width: 44,
											json_height: 54,
											json_left: 40,
											json_top: 10,
										};
										break;
									case 4:
										style = {
											json_width: 55,
											json_height: 54,
											json_left: 35,
											json_top: 10,
										};
										break;
								}
								return {
									...size,
									...style,
									json_transformRotate: 0,
								};
							});
							data = {
								...data,
								cartStyle: {
									transform: "translate(32%, -106%)",
								},
							};
							break;
						case 41166794653902:
							data = {
								...data,
								cartStyle: {
									transform: "translate(-1%, -81%)",
									width: 30,
								},
							};
							break;
						case 40999509065934:
							data = {
								...data,
								cartStyle: {
									transform: "translate(-16%, -112%)",
									width: 25,
								},
							};
							break;
						case 40992657866958:
							data = {
								...data,
								cartStyle: {
									width: 25,
									transform: "translate(10%, -36%) rotate(-13deg)",
								},
							};
							break;
					}
				}
			}
			setProduct(data);
			setLoading(false);
		};
		getProduct();
	}, [id, account_address]);

	// useEffect(() => {
	// 	console.log("Updated state:	", nftimages);
	// 	fetchNFTs();
	// }, [account_address]);

	let url;

	const fetchNFTs = async () => {
		try {
			const { ethereum } = window;
			let nftContinution;
			let contPages;
			let nftPorts; //mainnet
			let nftPorts1; //rinkeby
			let nftDataArray = []; //mainnet

			if (nftFetchLoop > 1 && onlyOnce === true) {
				return
			}


			let options = {
				method: 'GET',
				url: `https://api.nftport.xyz/v0/accounts/${account_address}`,
				params: { chain: 'ethereum', page_size: '50', include: 'metadata', continuation: nftFetchContinuation },
				headers: {
					'Content-Type': 'application/json',
					Authorization: '45ae9aa7-389e-4e2d-b5b8-3cf70b265a04'
				}
			};

			nftPorts = await axios.request(options);
			console.log("nftPrts>>>>>>>>", nftPorts);


			nftContinution = nftPorts.data.continuation;
			console.log("nftContination>>>>>>>>>", nftContinution);
			setNftFetchContinuation(nftContinution)
			const nftTotal = nftPorts.data.total;
			console.log(nftTotal, "<<<<<<<<total")
			contPages = Math.floor(nftTotal / 50) + 1;
			console.log(contPages, "<<<<totalPages")


			if (contPages < 1) {
				contPages = 1
			}
			console.log(contPages, "<<<<totalPages")

			if (nftTotal < 50) {
				setIncrementPage(0)
				setShowLoadNfts(false)
				setNftFetchLoop(1)
				setOnlyOnce(true);
			}




			nftPorts?.data?.nfts?.forEach((nft, index) => {
				let resUrl = fixURL(nft.file_url);

				if (!nftDataArray.find(nftD => nftD.token_id === nft.token_id)) {
					nftDataArray.push({ id: index + 1, image_url: resUrl, token_id: nft.token_id });
				}
			});

			setNftFetchLoop(nftFetchLoop + 1);

			// if (nftContinution == null) {
			// 	return
			// }


			let options1 = {
				method: 'GET',
				url: `https://api.nftport.xyz/v0/accounts/${account_address}`,
				params: { chain: 'ethereum', page_size: '50', include: 'metadata', continuation: nftFetchContinuation },
				headers: {
					'Content-Type': 'application/json',
					Authorization: '45ae9aa7-389e-4e2d-b5b8-3cf70b265a04'
				}
			};

			nftPorts1 = await axios.request(options1);
			nftContinution = nftPorts1.data.continuation;
			setNftFetchContinuation(nftContinution)
			console.log("nftPrtsWhile>>>>>>>>", nftPorts1);





			nftPorts1?.data?.nfts?.forEach((nft, index) => {
				let resUrl = fixURL(nft.file_url);

				if (!nftDataArray.find(nftD => nftD.token_id === nft.token_id)) {
					nftDataArray.push({ id: index + (incrementPage * 50) + 1, image_url: resUrl, token_id: nft.token_id });
				}

			});



			setIncrementPage(incrementPage + 1);




			console.log(nftDataArray, "<<<mainnetArray")

			setNftImages(prevNftImags => {
				console.log(prevNftImags)
				if (prevNftImags) {
					return [...prevNftImags, ...nftDataArray]
				} else {
					return [...nftDataArray]
				}
			});

		} catch (err) {

		}
	};
	const fixURL = (url) => {
		console.log(url, "url ====...");
		if (url?.startsWith("ipfs")) {
			if (url.startsWith("ipfs://ipfs/")) {
				let splitData1 = url?.split("ipfs://ipfs/")[1];
				return "ipfs.io/ipfs/" + splitData1;
			}
			let splitData = url?.split("ipfs://")[1];

			return "https://ipfs.io/ipfs/" + splitData;
		} else {
			return url + "?format=json";
		}
	};

	// increament deacrement counter
	const handleQuantity = (type) => {
		if (type === "dec") {
			quantity > 1 && setQuantity(quantity - 1);
		} else {
			setQuantity(quantity + 1);
		}
	};

	// modal
	const [isModalVisible, setIsModalVisible] = useState(false);

	const showModal = () => {
		setIsModalVisible(true);
	};
	const handleCancel = () => {
		setIsModalVisible(false);
	};


	useEffect(() => {
		fetchNFTs();
		setImgData();
		setShow(false);
		setselectedImage();
		if (id === "40999393001678") {
			setselectedSize(0);
		} else {
			setselectedSize(1);
		}
		if (id === "41138846007502") {
			setselectedValue(0);
			setselectedthumb(1);
		} else {
			setselectedValue(1);
		}
	}, [id, account_address]);

	const [selectedSize, setselectedSize] = useState(1);
	const [selectedValue, setselectedValue] = useState("");
	const [selectedThumb, setselectedthumb] = useState("0");
	console.log(selectedSize, "selectedSize");
	console.log(selectedValue, "selectedValue");
	console.log(selectedThumb, "selectedThumb");
	function ColorChange(id) {
		setselectedValue(id);
	}
	function ThumbnailChange(id) {
		setselectedValue(id);
		if (product.id === 41138846007502) {
			setselectedSize(id);
		}
		let cartStyle;

		switch (id) {
			case 1:
				cartStyle = {
					transform: "translate(32%, -106%)",
				};
				break;
			case 2:
				cartStyle = {
					transform: "translate(27%, -93%)",
					height: 44,
				};
				break;
			case 3:
				cartStyle = {
					transform: "translate(26%, -74%)",
					height: 55,
				};
				break;
			case 4:
				cartStyle = {
					transform: "translate(20%, -74%)",
					width: 55,
					height: 55,
				};
				break;
		}
		if (product.id === 41138846007502) {
			setProduct((prevProductState) => {
				return {
					...prevProductState,
					cartStyle: cartStyle,
				};
			});
		}
		if (id === "41138846007502") {
			setselectedthumb(1);
		} else {
			setselectedthumb(0);
		}
	}
	console.log(selectedThumb, "selectedThumb");

	function selectedImageFunc(url) {
		setselectedImage(url);
	}
	console.log(selectedImage, "selectedImage");
	// useEffect(() => {
	//     const getnftImages = async () => {
	//         // con
	//         const response = await fetch(
	//             `https://my-json-server.typicode.com/vinod-kushwah/imagelist/images`
	//         );
	//         setNftImages(await response.json());
	//     };
	//     getnftImages();
	// }, []);
	//console.log(nftimages, 'nftimages')

	const handleSelectedSize = (id) => {
		setselectedSize(id);
		if (product.id === 41138846007502) {
			ThumbnailChange(product.thumbnails.find((thm) => thm.id == id).id);
		}
	};

	const handleNftFileChange = (event) => {
		if (event.target.files && event.target.files[0]) {
			let image_url = URL.createObjectURL(event.target.files[0])
			setselectedImage(image_url)
			setNftImages((prevNftImages) => {
				return [...prevNftImages, { id: prevNftImages.length + 1, image_url: image_url, token_id: Date.now() }]
			})
		}
	}

	const handleUploadFileClick = event => {
		nftUpload.current.click();
	};
	return (
		<>
			<div className="single-product-sec pt-5 pb-5">
				<div className="container-wrap">
					<div className="product-row row">
						{product ? (
							<div className="single-photo-product col-md-6">
								{selectedThumb == "1" && selectedValue == "0" ? <img src={product.images} alt="ShirtBlack" /> : ""}
								{product.thumbnails.map((res) => {
									return <div key={res.id}>{res.id === selectedValue ? <img src={res.url} alt="ShirtBlack" /> : null}</div>;
								})}

								<div className="thumbnail-imgs row d-flex gx-2 gy-2 pt-4">
									{product.thumbnails.map((res) => {
										return (
											<div key={res.id}>
												<p className="col" onChange={() => ThumbnailChange(res.id)}>
													<input type="radio" name="image" defaultChecked={selectedThumb === res.id} value={res.id} />
													<img src={res.url} alt={product.title} key={res.id} />
												</p>
											</div>
										);
									})}
								</div>
							</div>
						) : (
							""
						)}
						<div className="prodcut-description col-md-6">
							<h2>
								<b>{product.title}</b>
							</h2>
							<h2 className="product-price pb-4">${product && product?.size.length && selectedSize !== "" ? product.size.find((size) => size.id === selectedSize)?.price : product.price}</h2>
							<div className="product-inn-details">
								{product.size && product.size.length ? (
									<div className="size pt-4">
										<label className="size-lable bold-txt">Size</label>
										<div className="size-container">
											{product ? (
												<div className="size-elements">
													{product.size.map((res) => {
														return (
															<div className="size-element" key={res.id} onChange={() => handleSelectedSize(res.id)}>
																<input checked={selectedSize === res.id} type="radio" name="size" value={res.id} />
																<label htmlFor={res.type}>{res.type}</label>
															</div>
														);
													})}
												</div>
											) : (
												""
											)}
										</div>
									</div>
								) : (
									""
								)}
								{product.color && product.id != 41138846007502 ? (
									<div className="color pt-4">
										<label className="color-lable bold-txt">Color</label>
										<div className="color-container">
											<div className="color-elements">
												{product ? (
													<div className="size-elements">
														{product.color.map((res) => {
															return (
																<div className="color-element" onChange={() => ColorChange(res.id)} key={res.id}>
																	<Tooltip title={res.color_name}>
																		<input defaultChecked={selectedValue === res.id} type="radio" name="color" value={res.id} />
																		<label htmlFor={res.color_name} className={selectedValue === res.id ? `checked_label ${res.color_name}` : res.color_name}></label>
																	</Tooltip>
																</div>
															);
														})}
													</div>
												) : (
													""
												)}
											</div>
										</div>
									</div>
								) : (
									""
								)}
								<div className="quantity pt-4">
									<label className="color-lable bold-txt">Quantity</label>
									<div className="productarea">
										<button className="productminus" onClick={() => handleQuantity("dec")}>
											<i className="fa fa-minus" aria-hidden="true"></i>
										</button>

										<span className="product-detail-amount product__quat">{quantity}</span>
										<button className="productplus" onClick={() => handleQuantity("inc")}>
											<i className="fa fa-plus" aria-hidden="true"></i>
										</button>
									</div>
								</div>
							</div>
							<button className="btn btn-primary personalize my-4" type="submit" onClick={showModal}>
								Personalize
							</button>
							<img src={securepartners} alt="securepartners" className="securepartners" />
							<Modal visible={isModalVisible} onCancel={handleCancel} className="cart-popup">
								<div className="row">
									<div className="col-md-6 left-popup-sec">
										{product ? (
											<div id="product-overview" className="size-elements edit-img">
												{selectedThumb == "1" && selectedValue == "0" ? <img src={product.images} alt="ShirtBlack" /> : ""}
												{product.thumbnails.map((res) => {
													return res.id === selectedValue ? (
														<div key={res.id}>
															<img src={res.url} alt="ShirtBlack" />
														</div>
													) : null;
												})}
												{/* Edit image */}
												{selectedImage ? (
													product.id === 41138846007502 ? (
														<div
															className="container-select-img text-center default-style"
															style={{
																position: "absolute",

																width: `${product.size.find((s) => s.id == selectedValue)?.json_width}%`,
																left: `${product.size.find((s) => s.id == selectedValue)?.json_left}%`,
																top: `${product.size.find((s) => s.id == selectedValue)?.json_top}%`,
																height: `${product.size.find((s) => s.id == selectedValue)?.json_height}%`,
																transform: `rotate(${product.size.find((s) => s.id == selectedValue)?.json_transformRotate}deg)`,
															}}>
															<img src={selectedImage} />
														</div>
													) : (
														<div
															className="container-select-img text-center"
															style={{
																position: "absolute",
																width: `${product.json_width}%`,
																left: `${product.json_left}%`,
																top: `${product.json_top}%`,
																height: `${product.json_height}%`,
																transform: `rotate(${product.json_transformRotate}deg)`,
															}}>
															<img src={selectedImage} />
														</div>
													)
												) : (
													""
												)}
											</div>
										) : (
											""
										)}
									</div>
									<div className="col-md-6 right-popup-sec mt-4 mt-md-0 px-md-0 px-2 pb-md-0 pb-4">
										<div className="modal-right-side">
											<div className="button-on-dark d-flex">
												<div className="option-name pb-2"></div>
												<div className="fileadd-box">
													{/* <input type="file" accept="image/*"
														onClick={(event) => {
															event.target.value = null
														}} onChange={onChangePicture} />
													<button className="btn btn-primary btn-dark select-btn">
														Select Image
													</button> */}
													<button className="btn btn-primary btn-dark select-btn" onClick={() => setShow(!show)}>
														{" "}
														Select Image
													</button>
												</div>
												{selectedImage ? (
													<button className="btn btn-primary btn-dark close-controls" onClick={(e) => setselectedImage()}>
														X
													</button>
												) : (
													""
												)}
											</div>
											{show && nftimages ? (
												<>
													<div className="thumbnail-imgs nft-images row d-flex pt-3 box-select-img">
														{nftimages.map((res) => {
															return (
																<div className="modal_thum" key={res.id}>
																	<p className="col mb-0 px-1 px-md-2 py-2" onClick={() => selectedImageFunc(res.image_url)}>
																		<input type="radio" name="image" defaultChecked={selectedImage === res.id} value={res.id} />
																		<img src={res.image_url} alt={res.id} />
																	</p>
																</div>
															);
														})}
													</div>
													<div className="upload-nft-btn-wrapper">

														<button className="btn btn-primary btn-dark select-btn" onClick={async () => {
															await fetchNFTs()
														}}>Load More</button>
														<input ref={nftUpload} onChange={handleNftFileChange} type="file" accept=".jpg,.jpeg, .png, ,gif" hidden style={{ display: 'none' }} />
														<button onClick={handleUploadFileClick} className="btn btn-primary btn-dark select-btn">Upload Nft</button>
													</div>
												</>
											) : (
												""
											)}
											<div className="product-thumbnails">
												<div className="option-name pt-2"></div>
												{product ? (
													<div className="thumbnail-imgs row d-flex pt-3 pb-3">
														{product.thumbnails.map((res, id) => {
															return (
																<div className="modal_thum" key={res.id}>
																	<p className="col mb-0 px-1 px-md-2" onChange={() => ThumbnailChange(res.id)}>
																		<Tooltip title={product.id === 41138846007502 ? product?.size[id]?.type : product.title}>
																			<input type="radio" name="image" defaultChecked={selectedThumb === res.id} value={res.id} />
																			<img src={res.url} alt={product.title} key={res.id} />
																		</Tooltip>
																	</p>
																</div>
															);
														})}
													</div>
												) : (
													""
												)}
												<button className="btn btn-primary personalize cartpopup-button mt-4 mt-md-0" type="submit" onClick={() => addproduct(product)}>
													<span>${selectedSize && product ? product.size.find((size) => size.id === selectedSize).price : product.price}</span> - ADD TO CART
												</button>
											</div>
										</div>
									</div>
								</div>
							</Modal>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Product;
