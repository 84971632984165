import { React, useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { useSelector } from "react-redux";
import { NavLink, Link } from "react-router-dom";
import sitelogo from "../Assets/Images/sitelogo.png";
import { addAccountDetail, getAccountDetail } from "../utility/account.storage";
import SearchBar from "./SearchBar";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { add_account, delete_account } from "../redux/action/account";

function Header() {
	const [currentAccount, setCurrentAccount] = useState(null);
	const state = useSelector((state) => state.handleCart);
	const account_address = useSelector((state) => state.handleAccount);
	const [expanded, setExpanded] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		if (account_address) {
			setCurrentAccount(account_address);
		}
	}, [account_address]);

	const connectWalletHandler = async () => {
		const { ethereum } = window;

		// Check if MetaMask is installed on user's browser
		if (window.ethereum) {
			const accounts = await ethereum.request({ method: "eth_requestAccounts" });
			const chainId = await ethereum.request({ method: "eth_chainId" });

			// Check if user is connected to Mainnet
			//wallet can be put in state and checked for state updation
			let wallet = accounts[0];

			ethereum.on("accountsChanged", (accounts) => {
				wallet = accounts[0];
				console.log(wallet, "<<<disconnected");
				//disconnected gives undefined
				//otherwise the new account address
				if (!wallet) {
					dispatch(delete_account());
					setCurrentAccount(null);
				}
			});

			if (wallet) {
				dispatch(add_account(wallet));
				addAccountDetail(wallet);
				setCurrentAccount(wallet);
			}

			//await Moralis.authenticate();
			//await Moralis.authenticate();

			console.log(wallet);
		} else {
			alert("Please install MetaMask or open Metamask app browser or Coinbase app browser");
		}
	};

	return (
		<>
			<div className="header_wraper">
				<div className="topbar shippingbar mb-md-4 pt-3 pb-3"></div>
				<div className="container-wrap">
					<Navbar collapseOnSelect expand="xl" expanded={expanded}>
						<div className="logo">
							<Link to="/">
								<img src={sitelogo} alt="sitelogo" />
							</Link>
						</div>
						<div className="buttons-header d-flex">
							<SearchBar />
							{currentAccount !== null ? (
								<button className="btn btn-dark add-to-wallet">{currentAccount.substring(0, 10)}....</button>
							) : (
								<button className="modal-con-btn" onClick={connectWalletHandler}>
									Connect Wallet
								</button>
							)}
						</div>
						<div className="cart">
							<Link to="/cart">
								{" "}
								<i className="fa fa-shopping-cart" aria-hidden="true">
									<span>{state.length}</span>
								</i>
							</Link>
						</div>
						<div className="nav-wraper mt-2 mt-md-5">
							<Navbar.Toggle aria-controls="responsive-navbar-nav" id="btnMemu" onClick={() => setExpanded(expanded ? false : "expanded")} />
							<Navbar.Collapse id="responsive-navbar-nav">
								<Nav className="me-auto" onClick={() => setExpanded(false)}>
									{/* <NavLink to="/" exact="true" activeclassname="active">Home</NavLink> */}
									<li>
										<NavLink to="/product/41138846007502" activeclassname="active">
											Metal Sign
										</NavLink>
									</li>
									<li className="submenu-has">
										<Nav.Link>
											Apparel <i className="fa fa-caret-down" aria-hidden="true"></i>
										</Nav.Link>
										<ul className="submenus">
											<NavLink to="/product/41166794653902" activeclassname="active">
												T-shirt
											</NavLink>
											<NavLink to="/product/40992657866958" activeclassname="active">
												hoodie
											</NavLink>
											<NavLink to="/product/40999509065934" activeclassname="active">
												ladies-t-shirt
											</NavLink>
										</ul>
									</li>
									<li>
										<NavLink to="/product/40999393001678" activeclassname="active">
											MUG
										</NavLink>
									</li>
								</Nav>
							</Navbar.Collapse>
						</div>
					</Navbar>
				</div>
			</div>
		</>
	);
}
export default Header;
