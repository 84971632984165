import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Radio } from 'antd';


function Shipping({ value,  onChildClick }) {
  const navigate = useNavigate();
  const [shippingprice, setshippingprice] = useState("55.43")
  const [shipngname, setshipngname] = useState("USPS Priority Mail International")
  function handleClick(event) {
    onChildClick(event.target.price); // pass any argument to the callback
    setshippingprice(event.target.price)
    setshipngname(event.target.shipname)
  }
  const handlePayment =(event)=>{
    navigate("/checkout/payment")
  }
 
  useEffect(() => {
    localStorage.setItem('shippingprice', shippingprice);
    localStorage.setItem('shipngname', shipngname);
  },[value]);

  return (
    <>
      <div className="form">
        <div className="form-content">
          <label className="label-head pt-3 pb-3">Shipping method</label>
          <fieldset className="method-box ship-method-box">
          <Radio.Group onChange={handleClick} defaultValue={1}>
            <Radio price="55.43" shipname="USPS Priority Mail International" value={1}>
              <label>USPS First Class Package International
                <span className="buss-days">7 to 21 business days</span>
              </label>
              <span className="radio__label__accessory">
                $55.43
              </span>
            </Radio>
            <Radio price="65.17" shipname="USPS Priority Mail International" value={2}>
            <label>DHL Express Worldwide
                <span className="buss-days">3 to 5 business days</span>
              </label>
              <span className="radio__label__accessory">
                $5.17
              </span>
            </Radio>
            <Radio price="70.06" shipname="USPS Priority Mail International" value={3}>
            <label>UUSPS Priority Mail International
                <span className="buss-days">6 to 10 business days</span>
              </label>
              <span className="radio__label__accessory">
                $70.06
              </span>
            </Radio>
            <Radio price="89.27" shipname="USPS Priority Mail International" value={4}>
            <label>USPS Priority Mail Express International
                <span className="buss-days">3 to 5 business days</span>
              </label>
              <span className="radio__label__accessory">
                $89.27
              </span>
            </Radio>
          </Radio.Group>
         </fieldset>
          <div className="btn-steps pt-4 pb-2 pb-lg-5">
            <button className="btns" onClick={handlePayment}>
              Continue to payment
            </button>
            <button className="back-button" onClick={() => navigate("/checkout/information")}>
              Return to information
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
export default Shipping;
