import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import shoppay from "../../Assets/Images/shoppay.png";
import { useForm } from "react-hook-form";
import { Checkbox } from "antd";
import PhoneInput from "react-phone-input-2";
import "antd/dist/antd.css";
import "react-phone-input-2/lib/style.css";
import { Select, MenuItem } from "@material-ui/core";
import darkgpay from "../../Assets/Images/dark_gpay.svg";
import paypalg from "../../Assets/Images/paypalg.svg";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";


function Information() {
  const navigate = useNavigate();
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [checked, setChecked] = useState(false)
  const [state, setState] = useState('');
  const [phone, setPhone] = useState('');
  const [country, setCountry] = useState("United States");
  const [region, setRegion] = useState("");


  const handleClick = () => setChecked(!checked)

  //continue to shipping btn
  const onSubmit = (data) => {
    navigate("/checkout/shipping")
    console.log(data);
  localStorage.setItem('email', data.email);
  localStorage.setItem('addressl', data.address);
  localStorage.setItem('zip', data.zip);
  localStorage.setItem('country', country);
  localStorage.setItem('region', region);
  localStorage.setItem('city', data.city);
  };
  console.log(watch("example"));

  //select country
  const selectCountry = (val) => {
    setCountry(val);
  };

  //select region
  const selectRegion = (val) => {
    setRegion(val);
  };

  return (
    <>
      <div className="form">
        <div className="line-header text-center">
          <h2 className="express-checkout text-center line-text">
            Express checkout
          </h2>
        </div>
        <div className="payment-button">
          <button className="shoppay">
            <img src={shoppay} alt={shoppay} />
          </button>
          <button className="paypal">
            <img src={paypalg} alt={paypalg} />
          </button>
          <button className="gpay">
            <img src={darkgpay} alt={darkgpay} />
          </button>
        </div>
        <div className="line-header text-center">
          <h2 className="line-text or-txt">OR</h2>
        </div>
        <div className="form-content">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="contact-information">
              <label className="label-head pb-2">Contact information</label>
              <p className="full-f">
                <input
                  placeholder="Email or mobile phone number"
                  {...register("email", { required: true })}
                  className="full-inp"
                />
                {errors.email && (
                  <small className="text-danger">
                    Enter a valid email or mobile phone number
                  </small>
                )}
              </p>
              <Checkbox type="checkbox" >
                Email me with news and offers
              </Checkbox>
            </div>
            <div className="shipping-address-wrap">
              <label className="label-head pb-2">Shipping address</label>
              <div className="name-fields">
                <span className="half-f">
                  <input
                    type="text"
                    placeholder="FirstName"
                    className="full-inp"
                  />
                </span>
                <span className="half-f">
                  <input
                    type="text"
                    className="full-inp"
                    placeholder="LastName"
                    {...register("lastname", { required: true })}
                  />
                  {errors.lastname && (
                    <small className="text-danger">Enter a last name</small>
                  )}
                </span>
              </div>
              <p className="full-f">
                <input
                  type="text"
                  placeholder="Address"
                  {...register("address", { required: true })}
                  className="full-inp"
                />
                {errors.address && (
                  <small className="text-danger">Enter an address</small>
                )}
              </p>
              <p className="full-f">
                <input
                  type="text"
                  placeholder="Apartment, suite, etc. (optional)"
                  className="full-inp"
                />
              </p>
              <p className="full-f">
                <input
                  type="text"
                  placeholder="City"
                  {...register("city", { required: true })}
                  className="full-inp"
                />
                {errors.address && <small className="text-danger">Enter a city</small>}
              </p>
              <div className="name-fields m-0 pb-3">
                <span className="half-f country-select select-box-wraP">
                  <label className="select-lable small-text">Country/Region</label>
                 <p className="mb-0 px-1">
                 <CountryDropdown
                    style={{ width: "150px" }}
                    value={country}
                    onChange={(val) => selectCountry(val)}
                  />
                 </p>
                  <p className="mb-0 px-1">
                  <RegionDropdown
                    country={country}
                    value={region}
                    onChange={(val) => selectRegion(val)}
                  />
                  </p>
                 <p className="mb-0 px-1">
                 <input type="number" placeholder="ZIP code" {...register("zip", { required: true })} className="full-inp" />
                  {errors.zip && (
                    <small className="text-danger">Enter zip code</small>
                  )}
                 </p>
                </span>
              </div>
              <Checkbox type="checkbox" >
                Text me with news and offers
              </Checkbox>
              <Checkbox onClick={handleClick} checked={checked} type="checkbox" className="d-flex" >
                Save my information for a faster checkout
                {checked ?
                  <div className="phone-show information-phone">
                    <span className="phone-label">Mobile phone number</span>
                    <PhoneInput
                      country={"us"}
                      value={phone}
                      onChange={(phone) => setState({ phone })}
                    />
                    <i className="fa fa-mobile" aria-hidden="true"></i>
                    <p className="small-text pt-2 mb-0">By signing up via text, you agree to receive recurring automated marketing messages, including cart reminders, at the phone number provided. Consent is not a condition of purchase. Reply STOP to unsubscribe. Reply HELP for help. Message frequency varies. Msg & data rates may apply. View our <Link to="/privacypolicy" className="text-decoration-none">Privacy Policy</Link> and <Link to="/tos" className="text-decoration-none"> Terms of Service.</Link></p>
                  </div>
                  : ''}
              </Checkbox>
            </div>
            <div className="btn-steps pt-4 pb-2 pb-lg-5">
              <input
                type="submit"
                className="btns"
                onSubmit={onSubmit}
                placeholder="Continue to Shipping"
                value="Continue to Shipping"
              />
              <button onClick={() => navigate("/cart")} className="back-button">
                Return to cart
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
export default Information;
