import React from 'react';
import SearchBar from '../SearchBar';

function Search() {
    return (
        <>
            <div className='container-wrap py-md-5 py-4'>
             <SearchBar/>
            </div>
        </>
    );
}

export default Search;