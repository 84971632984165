import {addItem, getCart} from "../../utility/cart.storage"

const cart = getCart() ? getCart() : [];
const handleCart = (state = cart,action) =>{
  const product = action.payload;
  switch(action.type){
      case "ADDITEM" :
        //   check if product is already exist
     const exit = state.find((x)=>x.id === product.id);
     if(exit){
         // incraese the quantity
         let result = state.map((x)=>
         x.id === product.id ? {...x, qty: x.qty+1,selectedProductDetail:x.selectedProductDetail ? [...x.selectedProductDetail, action.payload.selectedProductDetail]:[action.payload.selectedProductDetail]} :x
         );
         addItem(result)
         return result
     }else{
         const product = action.payload;
         let result = [
             ...state,
             {
                 ...product,
                 selectedProductDetail:[action.payload.selectedProductDetail],
                 qty:1,
             }
         ]
         addItem(result)
         return result
     }
      break;
     
      case "DELITEM":
        const exit1 = state.find((x)=>x.id === product.id);
        if(exit1.qty === 1){
            let result = state.filter((x)=>x.id !==exit1.id);
            addItem(result)
            return result
        }else{
            let result = state.map((x)=>
            x.id === product.id ?{...x, qty: x.qty-1, selectedProductDetail : x.selectedProductDetail.slice(0,-1)}:x
            );
            addItem(result)
            return result
        }
       break;
       case "REMOVE_ITEM":
        const item = state.find((x)=>x.id === product.id);
        if(item){
            let result = state.filter((x)=>x.id !==item.id);
            addItem(result)
            return result
        }


      default:
          return state;
          break;
  }
}
export default handleCart;
