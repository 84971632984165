export const addAccountDetail = (account) => {
	try {
		sessionStorage.setItem("account_address", account);
		return true;
	} catch (err) {
		return false;
	}
};

export const removeAccountDetail = () => {
	try {
		sessionStorage.removeItem("account_address");
		return true;
	} catch (err) {
		return false;
	}
};

export const getAccountDetail = () => {
	try {
		return sessionStorage.getItem("account_address");
	} catch (err) {
		return false;
	}
};
