import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from "react-dom/client";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import { Provider } from 'react-redux';
import store from './redux/store';
// import { Provider } from "react-moralis";

//Moralis.start();
//const appId="4Z6jLFCl7A5uHqSVy4pasXlT7eS4kG29sRWGVRUk";
//const serverUrl="https://bn7n97u0wnga.usemoralis.com:2053/server";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    
      <BrowserRouter>
        <App />
      </BrowserRouter>
 
  </Provider>
);
reportWebVitals();