import React, { useEffect, useState } from "react";
import { NavLink, Link, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import SlideToggle from "react-slide-toggle";
import { Button, Modal } from "react-bootstrap";
import RefundPolicy from "../Components/Pages/RefundPolicy";
import PrivacyPolicy from "../Components/Pages/PrivacyPolicy";
import Tos from "../Components/Pages/Tos";
import sitelogo from "../Assets/Images/sitelogo.png";
import Information from "./CheckoutForms/Information";
import Shipping from "./CheckoutForms/Shipping";
import Payment from "./CheckoutForms/Payment";
import ShirtBlack from '../Assets/Images/ShirtBlack.png';
import { useSelector } from "react-redux";

function Checkout() {
  const navigate = useNavigate();
  const [value, setValue] = useState('55.43');
  function handleChildClick(value) {
    setValue(value);
  }
  // console.log(value, 'value')
  const state = useSelector((state) => state.handleCart);
  // console.log(state.length)
  // add active class on mobile toggle sidebar
  const [activeSidebar, setActiveSidebar] = useState("false");
  const toggleSidebar = () => {
    setActiveSidebar(!activeSidebar);
  };

  const [discount, setDiscount] = useState()
  const [refund, setRefund] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [terms, setTerms] = useState(false);

  const location = useLocation();
  let pathurl = location.pathname;

  // refund policy
  const refundClose = () => setRefund(false);
  const refundShow = () => setRefund(true);

  // privacy policy
  const privacyClose = () => setPrivacy(false);
  const privacyShow = () => setPrivacy(true);

  // terms of service
  const termsClose = () => setTerms(false);
  const termsShow = () => setTerms(true);
  const TotalAmount = state.reduce((sum, i) => (
    sum += i.qty * i.price
  ), 0);

  let totalwithShip
  if (pathurl === "/checkout/shipping") {
    totalwithShip = Number(value) + Number(TotalAmount)
  } else {
    totalwithShip = Number(TotalAmount).toFixed(2)
  }
  const email = localStorage.getItem('email')
  const addressl = localStorage.getItem('addressl')
  const zip = localStorage.getItem('zip')
  const country = localStorage.getItem('country')
  const region = localStorage.getItem('region')
  const city = localStorage.getItem('city')
  const [shippingprice, setshippingprice] = useState("55.43")
  const [shipngname, setshipngname] = useState("USPS Priority Mail International")
  useEffect(() => {
    setshippingprice(localStorage.getItem('shippingprice'))
    setshipngname(localStorage.getItem('shipngname'))
  }, [value]);
  return (
    <>
      <div className="checkout">
        <div className="container-wrap checkout-wraper">
          <div className="mobile-top-logo text-center">
            <Link to="/">
              <img src={sitelogo} alt="sitelogo" className="mobile-show-lg" />
            </Link>
          </div>
          <div className="row-checkout row">
            <div className="main col-lg-6">
              <div className="wrap-out">
                <div className="checkout-log">
                  <Link to="/">
                    <img src={sitelogo} alt="sitelogo" />
                  </Link>
                </div>
                <nav className="breadcrumb-nav text-center">
                  <NavLink to="/cart">cart <i className="fa fa-angle-right" aria-hidden="true"></i></NavLink>
                  <NavLink to="/checkout/information">information <i className="fa fa-angle-right" aria-hidden="true"></i></NavLink>
                  <NavLink className={email ? "actveshipping" : "deactivate"} to="/checkout/shipping">shipping <i className="fa fa-angle-right" aria-hidden="true"></i></NavLink>
                  <NavLink className={pathurl === "/checkout/payment" ? "activepayment" : "deactivate"} to="/checkout/payment">payment</NavLink>
                </nav>
              </div>
              {pathurl != "/checkout/information" ?
                <div className="review-block mt-3">
                  {email ?
                    <ul className="review-block__inner">
                      <li className="left-block">Contact <span>{email}</span></li>
                      <li
                        className="right-block"
                        onClick={() => navigate("/checkout/information")}
                      >
                        Change
                      </li>
                    </ul> : ""}
                  {addressl ?
                    <ul className="review-block__inner">
                      <li className="left-block">Ship to <span>{addressl}, {zip} {city} {region}, {country}</span></li>

                      <li
                        className="right-block"
                        onClick={() => navigate("/checkout/information")}
                      >
                        Change
                      </li>
                    </ul> : ""}
                  {shippingprice && pathurl === "/checkout/payment" ?
                    <ul className="review-block__inner">
                      <li className="left-block">Method <span>{shipngname} <strong>{shippingprice}</strong></span></li>
                      <li
                        className="right-block"
                        onClick={() => navigate("/checkout/shipping")}
                      >
                        Change
                      </li>
                    </ul> : ""}
                </div> : ""}
              {pathurl === "/checkout/information" ? <Information /> : ""}
              {pathurl === "/checkout/shipping" ? <Shipping value={value} onChildClick={handleChildClick} /> : ""}
              {pathurl === "/checkout/payment" ? <Payment /> : ""}
              <div className="footer-modals">
                {/* Refund policy Modal */}
                <Button variant="transparent" onClick={refundShow}>
                  Refund policy
                </Button>
                <Modal
                  show={refund}
                  onHide={refundClose}
                  animation={false}
                  className="modal-policy-box"
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Refund policy</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <RefundPolicy />
                  </Modal.Body>
                </Modal>
                {/* Privacy policy Modal */}
                <Button variant="transparent" onClick={privacyShow}>
                  Privacy policy
                </Button>
                <Modal
                  show={privacy}
                  onHide={privacyClose}
                  animation={false}
                  className="modal-policy-box"
                >
                  <Modal.Header closeButton>
                    <Modal.Title> Privacy policy</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <PrivacyPolicy />
                  </Modal.Body>
                </Modal>
                {/* Terms of service Modal */}
                <Button variant="transparent" onClick={termsShow}>
                  Terms of service
                </Button>
                <Modal
                  show={terms}
                  onHide={termsClose}
                  animation={false}
                  className="modal-policy-box"
                >
                  <Modal.Header closeButton>
                    <Modal.Title> Terms of service</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Tos />
                  </Modal.Body>
                </Modal>
              </div>
            </div>

            <SlideToggle
              duration={400}
              noDisplayStyle={true}
              collapsed={true}
              render={({ onToggle, setCollapsibleElement }) => (
                <aside className={activeSidebar ? "sidebar col-lg-6" : "show-sidebar sidebar col-lg-6"}>
                  <div className="my-collapsible">
                    <span onClick={toggleSidebar} >
                      <span onClick={onToggle} className="toggle-sp">
                        <h5 className="txt-toggle">Click here to enter discount code<i className="fa fa-angle-down mobile-arr" aria-hidden="true"></i></h5>
                        <p className="price-togg-button"><strong>${state.reduce((sum, i) => (
                          sum += i.qty * i.price
                        ), 0).toFixed(2)}</strong></p>
                      </span>
                    </span>
                    <div className="my-collapsible__content" ref={setCollapsibleElement}>
                      <div className="aside-wraper">
                        <div className="products-cart-list">
                          {state.map((checkoutPro) => {
                            return (
                                <div className="show-cart-product pb-3 pt-2" key={checkoutPro.id}>
                                  <span className="name-p">
                                    <span className="wrap-img-prod">
                                      {checkoutPro.thumbnails.map((thumb) =>
                                        thumb.id === checkoutPro.selectedValue ?
                                          <img src={thumb.url} className="img-prod" alt={thumb.color_name} key={thumb.id}/>
                                          : ""
                                      )
                                      }
                                      <span className="quantity-show">{checkoutPro.qty}</span>
                                    </span>
                                    <div className="all-con">
                                      <p className="name-product mb-0">{checkoutPro.title}</p>

                                      {checkoutPro.selectedSize != 0 ?
                                        checkoutPro.size.map((size) =>
                                        size.id === checkoutPro.selectedSize ?
                                          <span className="size-product small-text" key={size.id}> {`${size.type} /`}</span>
                                          : ""
                                        )
                                        : null}
                                      {checkoutPro.color.map((col) =>
                                        col.id === checkoutPro.selectedValue ?
                                        <span className="color-product small-text" key={col.id}>  {col.color_name}</span>
                                        : ""
                                      )
                                      }
                                    </div>
                                  </span>
                                  <span>
                                    <p className="price-product">${checkoutPro.price}</p>
                                  </span>
                                </div>
                            );
                          })}


                        </div>
                        <div className="discount-wraper">
                          <input type="text" placeholder="Discount code" value={discount} onChange={(e) => setDiscount(e.target.value)} />
                          <button type="submit" disabled={!discount}>Apply</button>
                        </div>
                        <div className="total-sub">
                          <div className="row-t d-flex">
                            <span className="flex_1 thin-txt-gray">Subtotal</span>
                            <span className="subtotal-price">${state.reduce((sum, i) => (
                              sum += i.qty * i.price
                            ), 0).toFixed(2)}</span>
                          </div>
                          <div className="row-t d-flex">
                            <span className="flex_1 thin-txt-gray">Shipping</span>
                            <span className="small-text">{state.length != 0 ? value && pathurl === "/checkout/shipping" ? `$${value}` : "Calculated at next step" : "0.00"}</span>
                          </div>
                        </div>
                        <div className="row-t d-flex">
                          <span className="flex_1">Total</span>
                          <span className="total-price"><span className="thin-txt-gray">USD </span><strong>
                            ${state.length != 0 ? totalwithShip : "0.00"}</strong></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </aside>
              )}
            />

          </div>
        </div>
      </div>
    </>
  );
}

export default Checkout;
