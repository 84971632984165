import React, { useState, useCallback } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Radio, Checkbox } from "antd";
import { Select, MenuItem } from "@material-ui/core";
import "antd/dist/antd.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Visa from '../../Assets/Images/visa.svg';
import Master from '../../Assets/Images/master.svg';
import Amex from '../../Assets/Images/amex.svg';
import Discover from '../../Assets/Images/discover.svg';
import Paypal from '../../Assets/Images/paypal.png';
import BlankIcon from '../../Assets/Images/blankicon.svg'
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";

function Payment() {
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [state, setState] = useState('');
  const [bil, setBil] = useState(1);
  const [phone, setPhone] = useState('');
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const [country, setCountry] = useState("United States");
  const [region, setRegion] = useState("");


  //Billing address
  const onBilling = (e) => {
    setBil(e.target.value);
  };

  //Remeber me
  const handleClick = () => setChecked(!checked)

  //pay now btn
  //continue to shipping btn
  const onSubmit = (data) => {
    console.log(data);
  };

  //select country
  const selectCountry = (val) => {
    setCountry(val);
  };

  //select region
  const selectRegion = (val) => {
    setRegion(val);
  };

  return (
    <div className="form payment">
      <div className="form-content">
        
        {/* Payment method */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="payment-method pt-3">
            <label className="label-head">Payment</label>
            <p>All transactions are secure and encrypted.</p>
          </div>
          <fieldset className="method-box payment-method-box mt-3 mb-4">
            <Radio.Group name="radiogroup" defaultValue={1}>
              <Radio value={1}><b>credit card</b>
                <div className="payment-img">
                  <img src={Visa} alt="visa" />
                  <img src={Master} alt="master" />
                  <img src={Amex} alt="amex" />
                  <img src={Discover} alt="discover" />
                  <span className="small-add-more">and more…</span>
                </div>
                <div className="credit">
                  <div className="credit-form">
                    <p className="full-f">
                      <input type="number"
                        placeholder="Card number"
                        className="full-inp"
                        {...register("card number", { required: true })}
                      />
                      {errors.address && <small className="text-danger">Enter a valid card number</small>}
                    </p>
                    <p className="full-f">
                      <input type="text"
                        placeholder="Name on Card"
                        className="full-inp"
                        {...register("card name", { required: true })}
                      />
                      {errors.address && <small className="text-danger">Enter your name exactly as it’s written on your card</small>}
                    </p>
                    <div className="name-fields">
                      <span className="half-f">                      <input type="date"
                        placeholder="Expirations date"
                        className="full-inp"
                        {...register("expirations date", { required: true })}
                      />
                        {errors.address && <p className="text-danger">Enter a valid card expiry date</p>}
                      </span>
                      <span className="half-f">
                        <input type="number"
                          placeholder="Securty code"
                          className="full-inp"
                          {...register("security code", { required: true })}
                        />
                        {errors.address && (<small className="text-danger">Enter the CVV or security code on your card</small>)}
                      </span>
                    </div>
                  </div>
                </div>
              </Radio>
              <Radio value={2}><img src={Paypal} alt="paypal" className="paypal_l" />
                <div className="paypal">
                  <div className="paypal-method">
                    <div className="paypal-show-txt">
                      <img src={BlankIcon} alt="BlankIcon" className="blankicon" />
                      <p>
                        After clicking “Complete order”, you will be redirected to
                        PayPal to complete your purchase securely.
                      </p>
                    </div>
                  </div>
                </div>
              </Radio>
            </Radio.Group>
          </fieldset>
          {/* End Payment method */}

          {/* Billing method */}
          <label className="label-head pt-3">Billing address</label>
          <p>Select the address that matches your card or payment method.</p>
          <fieldset className="method-box billing-method mb-4">
            <div className="contbox__row">
              <div className="radio-wrap">
                <Radio.Group onChange={onBilling} value={bil}>
                  <Radio value={1}>
                    <b>Same as shipping address</b>
                  </Radio>
                  <Radio value={2} className="diff-billing">
                    <b>Use a different billing address</b>
                    <div className="bill">
                      <div className="bil-form">
                      <div className="name-fields m-0">
                <span className="half-f country-select select-box-wraP select-box-bill">
                  <label className="select-lable small-text">Country/Region</label>
                        <CountryDropdown
                          style={{ width: "150px" }}
                          value={country}
                          onChange={(val) => selectCountry(val)}
                        />
                        <RegionDropdown
                          country={country}
                          value={region}
                          onChange={(val) => selectRegion(val)}
                          className="state-bil"
                        />
                        </span>
                        </div>
                        <div className="name-fields">
                          <span className="half-f">
                            <input
                              type="text"
                              placeholder="FirstName"
                              className="full-inp"
                            />
                          </span>
                          <span className="half-f">
                            <input
                              type="text"
                              placeholder="LastName"
                              className="full-inp"
                              {...register("lastname", { required: true })}
                            />
                            {errors.lastname && (
                              <small className="text-danger">Enter a last name</small>
                            )}
                          </span>
                        </div>
                        <p className="full-f">
                          <input
                            type="text"
                            placeholder="Address"
                            {...register("address", { required: true })}
                            className="full-inp"
                          />
                          {errors.address && (
                            <small className="text-danger">Enter an address</small>
                          )}
                        </p>
                        <p className="full-f">
                          <input
                            type="text"
                            placeholder="Apartment, suite, etc. (optional)"
                            className="full-inp"
                          />
                        </p>
                        <div className="name-fields">
                          <span className="half-f">
                            <input
                              type="text"
                              placeholder="city"
                              {...register("city", { required: true })}
                              className="full-inp"
                            />
                            {errors.address && <small className="text-danger">Enter a city</small>}
                          </span>
                          <span className="half-f">
                            <input
                              type="text"
                              placeholder="Pin Code"
                              {...register("zip code", { required: true })}
                              className="full-inp"
                            />
                            {errors.address && <small className="text-danger">Enter a ZIP / postal code</small>}
                          </span>
                        </div>

                      </div>
                    </div>
                  </Radio>
                </Radio.Group>
              </div>
            </div>
          </fieldset>
          {/* End Billing method */}

          {/* Remember me */}
          <label className="label-head pt-3">Remember me</label>
          <fieldset className="method-box mt-3">
            <div className="content-box__row">
              <Checkbox onClick={handleClick} checked={checked} type="checkbox" >
                <b>Save my information for a faster checkout</b>
              </Checkbox>
            </div>
            {checked ?
              <div className="phone-show">
                <span className="phone-label">Mobile phone number</span>
                <PhoneInput
                  country={"us"}
                  value={phone}
                  onChange={(phone) => setState({ phone })}
                />
                <i className="fa fa-mobile" aria-hidden="true"></i>
                <p className="small-text pt-2 mb-0">By signing up via text, you agree to receive recurring automated marketing messages, including cart reminders, at the phone number provided. Consent is not a condition of purchase. Reply STOP to unsubscribe. Reply HELP for help. Message frequency varies. Msg & data rates may apply. View our <Link to="/privacypolicy" className="text-decoration-none">Privacy Policy</Link> and <Link to="/tos" className="text-decoration-none"> Terms of Service.</Link></p>
              </div>
              : ''}
          </fieldset>
          {/* End Remember me */}

          <div className="btn-steps pt-4 pb-2 pb-lg-5">
            <button className="btns" onSubmit={onSubmit}>Pay Now</button>
            <button
              className="back-button"
              onClick={() => navigate("/checkout/shipping")}
            >
              Return to Shipping
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
export default Payment;
