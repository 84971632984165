import React from 'react';

function Catalog() {
    return (
        <>
            <div className='container-wrap'>
                <h2>Catalog Components</h2>
            </div>
        </>
    );
}

export default Catalog;