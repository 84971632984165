const imgJson = [
	{
		id: 41166794653902,
		title: "men's t-shirt",
		images: require("./Assets/Images/products/1/images.png"),
		hover_images: require("./Assets/Images/products/1/hover_image.png"),
		thumbnails: [
			{
				id: 1,
				url: require("./Assets/Images/products/1/thumbnail_1.png"),
				color_name: "black",
			},
			{
				id: 2,
				url: require("./Assets/Images/products/1/thumbnail_2.png"),
				color_name: "white",
			},
			{
				id: 3,
				url: require("./Assets/Images/products/1/thumbnail_3.png"),
				color_name: "asphalt",
			},
			{
				id: 4,
				url: require("./Assets/Images/products/1/thumbnail_4.png"),
				color_name: "navy",
			},
		],
		category: "apparel",
		price: "34.99",
		size: [
			{
				id: 1,
				type: "small",
				price: 34.99,
			},
			{
				id: 2,
				type: "medium",
				price: 34.99,
			},
			{
				id: 3,
				type: "large",
				price: 34.99,
			},
			{
				id: 4,
				type: "XL",
				price: 34.99,
			},
			{
				id: 5,
				type: "2XL",
				price: 34.99,
			},
			{
				id: 6,
				type: "3XL",
				price: 34.99,
			},
			{
				id: 7,
				type: "4XL",
				price: 34.99,
			},
		],
		color: [
			{
				id: 1,
				color_name: "black",
			},
			{
				id: 2,
				color_name: "white",
			},
			{
				id: 3,
				color_name: "asphalt",
			},
			{
				id: 4,
				color_name: "navy",
			},
		],
		quantity: 0,
		json_top: 20,
		json_left: 39,
		json_width: 24,
		json_height: 24,
		json_transformRotate: 0,
	},
	{
		id: 40999509065934,
		title: "ladies t-shirt",
		images: require("./Assets/Images/products/2/images.png"),
		hover_images: require("./Assets/Images/products/2/hover_image.png"),
		thumbnails: [
			{
				id: 1,
				url: require("./Assets/Images/products/2/thumbnail_1.png"),
				color_name: "black",
			},
			{
				id: 2,
				url: require("./Assets/Images/products/2/thumbnail_2.png"),
				color_name: "gray",
			},
			{
				id: 3,
				url: require("./Assets/Images/products/2/thumbnail_3.png"),
				color_name: "navy",
			},
		],
		category: "apparel",
		price: "34.99",
		size: [
			{
				id: 1,
				type: "small",
				price: 34.99,
			},
			{
				id: 2,
				type: "medium",
				price: 34.99,
			},
			{
				id: 3,
				type: "large",
				price: 34.99,
			},
			{
				id: 4,
				type: "XL",
				price: 34.99,
			},
			{
				id: 5,
				type: "2XL",
				price: 35.99,
			},
			{
				id: 6,
				type: "3XL",
				price: 36.99,
			},
		],
		color: [
			{
				id: 1,
				color_name: "black",
			},
			{
				id: 2,
				color_name: "gray",
			},
			{
				id: 3,
				color_name: "navy",
			},
		],
		quantity: 0,
		json_top: 20,
		json_left: 35,
		json_width: 24,
		json_height: 24,
		json_transformRotate: 0,
	},
	{
		id: 40992657866958,
		title: "hoodie",
		images: require("./Assets/Images/products/3/images.png"),
		hover_images: require("./Assets/Images/products/3/hover_image.png"),
		thumbnails: [
			{
				id: 1,
				url: require("./Assets/Images/products/3/thumbnail_1.png"),
				color_name: "black",
			},
			{
				id: 2,
				url: require("./Assets/Images/products/3/thumbnail_2.png"),
				color_name: "asphalt",
			},
			{
				id: 3,
				url: require("./Assets/Images/products/3/thumbnail_3.png"),
				color_name: "navy",
			},
		],
		category: "apparel",
		price: "59.99",
		size: [
			{
				id: 1,
				type: "small",
				price: 59.99,
			},
			{
				id: 2,
				type: "medium",
				price: 59.99,
			},
			{
				id: 3,
				type: "large",
				price: 59.99,
			},
			{
				id: 4,
				type: "XL",
				price: 59.99,
			},
			{
				id: 5,
				type: "2XL",
				price: 60.99,
			},
			{
				id: 6,
				type: "3XL",
				price: 61.99,
			},
		],
		color: [
			{
				id: 1,
				color_name: "black",
			},
			{
				id: 2,
				color_name: "asphalt",
			},
			{
				id: 3,
				color_name: "navy",
			},
		],
		quantity: 0,
		json_top: 35,
		json_left: 39,
		json_width: 24,
		json_height: 24,
		json_transformRotate: -17,
	},
	{
		id: 40999393001678,
		title: "11OZ COFFEE MUG",
		images: require("./Assets/Images/products/4/images.jpg"),
		hover_images: require("./Assets/Images/products/4/hover_image.jpg"),
		thumbnails: [
			{
				id: 1,
				url: require("./Assets/Images/products/4/thumbnail_1.jpg"),
				color_name: "black",
			},
			{
				id: 2,
				url: require("./Assets/Images/products/4/thumbnail_2.jpg"),
				color_name: "white",
			},
		],
		category: "groceries",
		price: "24.99",
		size: [],
		color: [
			{
				id: 1,
				color_name: "black",
			},
			{
				id: 2,
				color_name: "white",
			},
		],
		quantity: 0,
		json_top: 25,
		json_left: 20,
		json_width: 48,
		json_height: 48,
		json_transformRotate: 0,
	},
	{
		id: 41138846007502,
		title: "METAL SIGN",
		images: require("./Assets/Images/products/5/images.png"),
		hover_images: require("./Assets/Images/products/5/hover_image.png"),
		thumbnails: [
			{
				id: 1,
				url: require("./Assets/Images/products/5/thumbnail_1.png"),
				color_name: "blue",
			},
			{
				id: 2,
				url: require("./Assets/Images/products/5/thumbnail_2.png"),
				color_name: "blue",
			},
			{
				id: 3,
				url: require("./Assets/Images/products/5/thumbnail_3.png"),
				color_name: "blue",
			},
			{
				id: 4,
				url: require("./Assets/Images/products/5/thumbnail_4.png"),
				color_name: "blue",
			},
		],
		category: "METAL SIGN",
		price: "99.99",
		size: [
			{
				id: 1,
				type: "12x12",
				price: 99.99,
			},
			{
				id: 2,
				type: "18x18",
				price: 149.99,
			},
			{
				id: 3,
				type: "18x24",
				price: 179.99,
			},
			{
				id: 4,
				type: "24x24",
				price: 199.99,
			},
		],
		color: [],
		quantity: 0,
		json_top: 25,
		json_left: 20,
		json_width: 48,
		json_height: 48,
		json_transformRotate: 0,
	},
];
export default imgJson;
